import React from "react"
import { Helmet } from "react-helmet"
import { withPrefix, Link } from "gatsby"
import $ from "jquery"
import Layout from "../components/layout"
import { LazyLoadImage } from 'react-lazy-load-image-component';
import 'react-lazy-load-image-component/src/effects/blur.css';

import Footer from "../components/footer"
import Drawer from "../components/drawer"

import "../styles/shul.scss"

import hamMenuIcon from "../images/ham_menu_icon.svg"
import hamMenuClose from "../images/ham_menu_close.svg"
// import dropDownArrow from "../images/drop-down-icon.svg"

import vaziraniV from "../images/vazirani-v.png"
import vaziraniFullLogo from "../images/vazirani-full-logo-yellow.png"
// import shulLogo from "../images/shul-logo-yellow.png"

import tallerSketch from "../images/taller-sketch.webp"
// import tallerSketchPlaceholder from "../images/taller-sketch-placeholder.jpg"

import conceptGold from "../images/CONCEPT GOLD.png"
import cadGold from "../images/CAD GOLD.png"
import ergoGold from "../images/ERGO GOLD.png"
import cncGold from "../images/CNC GOLD.png"
import productGold from "../images/PRODUCT GOLD.png"

import volvoGold from "../images/Volvo Transparent gold drk.png"
import yamahaGold from "../images/Logo_Yamaha_transparent gold drk.png"
import rrGold from "../images/Rolls-Royce-logo gold dark.png"
import jagGold from "../images/Jaguar_2012_logo_gold dark.png"
import lrGold from "../images/Land rover gold drk.png"

import ourCustomersMap from "../images/World-Map NEW DESIGN.jpg"

//React image gallery plugin
import ImageGallery from 'react-image-gallery';

const images = [
  {
    original: 'https://i.ibb.co/QDhxjFG/8-DESIGN-GALLERY.jpg',
  },
  {
    original: 'https://i.ibb.co/cNN16j5/9-DESIGN-GALLERY-Fotor3.jpg',
  },
  {
    original: 'https://i.ibb.co/JCFRPLp/13-DESIGN-GALLERY.jpg',
  },
  {
    original: 'https://i.ibb.co/nkfsRDZ/DESIGN-GALLERY-VR-Fotor3.jpg',
  },
  {
    original: 'https://i.ibb.co/GR2ycFG/DESIGN-GALLERY-Fotor43.jpg',
  },
  {
    original: 'https://i.ibb.co/WWQCTSh/DESIGN-GALLERY2-Fotor2-Fotore.jpg',
  },
  {
    original: 'https://i.ibb.co/RHQXLJX/5-DESIGN-GALLERY.jpg',
  },
  {
    original: 'https://i.ibb.co/rZJF2MQ/11-DESIGN-GALLERY.jpg',
  },
  {
    original: 'https://i.ibb.co/W5Xygg2/5-DESIGN-GALLERYaero.jpg',
  },
  {
    original: 'https://i.ibb.co/rtKW1bc/2-DESIGN-GALLERY-Fotor.jpg',
  },
];

// markup
class DesignPage extends React.Component {

  componentDidMount() {
    $("#services").hover(function () {
      $(".drop-down").toggleClass("active");
    });

    $("#services-mobile").click(function () {
      $(".drop-down").toggleClass("active");
    });
    $(".drawer-icon").click(function () {
      $(".drawer").toggleClass("active");
      $(".drawer-icon").toggleClass("close");
      $(".drawer-icon-close").toggleClass("close");
    });


  }




  render() {

    return (
      <React.Fragment>
        <Helmet>
          <meta charset="utf-8" />
          <title>Vazirani Automotive | Design</title>
          <meta name="viewport" content="width=device-width, initial-scale=1.0"></meta>
          <meta name="theme-color" content="#1E1E24" />
          <meta name="description" content="From defining the concept to sketch ideation to a fully functioning prototype. Our studio is equipped and skilled to take care of any aspect of the design process for your needs to provide a sexy outcome." />
          <meta property="og:title" content="Shul | Design" />
          <meta name="keywords" content="Car design studio, car design, car design consulting, electric car build, electric car, EV engineering, electric car engineering, electric vehicle components, ev body design, EV designer, EV design, EV powertrain, Electric engine, futuristic car designer, best car design company, best EV company, best ev design company, electric car battery maker, ev battery pack, electric car maker, electric vehicle design, electric vehicle design studio, electric car design, electric car manufacturing, electric car prototype, electric car prototyping, best car designer, best electric car, best electric car builder, car designer india, car design studio india, car design in india" />
          <meta property="og:image" content="../images/gallery/DESIGN GALLERY2_Fotor2_Fotore.jpg" />
        </Helmet>
        <div>
          <img alt="drawer-icon" class="drawer-icon" height="17px" src={hamMenuIcon}></img>
          <img alt="drawer-close-icon" class="drawer-icon-close" height="17px" src={hamMenuClose}></img>

          <Drawer></Drawer>

          <div class="wrapper">
            <div class="header" magic magicId="header">
              <div class="shul-v-logo">
                <Link to="/"><img alt="vazirani-v" src={vaziraniV}></img></Link>
              </div>
              <div class="nav" style={{ justifySelf: 'right' }}>
                <Link fade to="/">Shul</Link>
                <span class="shul-u">_</span>
              </div>
              {/* <div class="divider"></div> */}
              <div class="nav">
                <Link fade to="/ekonk">Ekonk</Link>
                {/* <a id="services">Ekonk
                            <img class="drop-down-arrow" src={dropDownArrow}></img>
                        </a> */}
                {/* <div class="drop-down">
                            <Link to="/design">Design</Link>
                            <div class="divider"></div>
                            <Link to="/engineering">Engineering</Link>
                        </div> */}
              </div>
              {/* <div class="divider"></div> */}
              <div class="nav">
                <Link fade to="/dico">Dico</Link>
              </div>

              <div class="shul-vazirani-logo">
                <Link to="/"><img alt="vazirani-logo" src={vaziraniFullLogo}></img></Link>                    </div>
            </div>

            <Layout>
              <div class="design-sec1" >

                {/* style={{ backgroundImage: `url(${tallerSketch})`}} */}

                <LazyLoadImage class="design-sec1-bg" src={tallerSketch} effect="blur"></LazyLoadImage>
                <div class="design-feature-icons">
                  <div>
                    <LazyLoadImage effect="blur" alt="concept-design" src={conceptGold}></LazyLoadImage>
                    <p>Concept</p>
                  </div>
                  <div>
                    <LazyLoadImage effect="blur" alt="cad-design" src={cadGold}></LazyLoadImage>
                    <p>CAD</p>
                  </div>
                  <div>
                    <LazyLoadImage effect="blur" alt="ergonomics-design" src={ergoGold}></LazyLoadImage>
                    <p>Ergonomics</p>
                  </div>
                  <div class="push">
                    <LazyLoadImage effect="blur" alt="prototype-design" src={cncGold}></LazyLoadImage>
                    <p>Prototyping</p>
                  </div>
                  <div class="push2">
                    <LazyLoadImage effect="blur" alt="product-design" src={productGold}></LazyLoadImage>
                    <p>Product</p>
                  </div>
                </div>
              </div>

              <div class="design-sec2">
                <div class="shul-gallery">
                  {/* <!-- <img src="../images/8DESIGN GALLERY.jpg"> --> */}
                  {/* <div id="auto">
                    <div><img src="../images/8DESIGN GALLERY.jpg" alt="" /></div>
                    <div><img src="../images/9DESIGN GALLERY_Fotor3.jpg" alt="" /></div>
                    <div><img src="../images/13DESIGN GALLERY.jpg" alt="" /></div>
                    <div><img src="../images/DESIGN GALLERY-VR_Fotor3.jpg" alt="" /></div>
                    <div><img src="../images/DESIGN GALLERY_Fotor43.jpg" alt="" /></div>
                    <div><img src="../images/DESIGN GALLERY2_Fotor2_Fotore.jpg" alt="" /></div>
                    <div><img src="../images/5DESIGN GALLERY.jpg" alt="" /></div>
                    <div><img src="../images/11DESIGN GALLERY.jpg" alt="" /></div>
                    <div><img src="../images/5DESIGN GALLERYaero.jpg" alt="" /></div>
                    <div><img src="../images/2DESIGN GALLERY_Fotor.jpg" alt="" /></div>
                </div> */}
                  <div class="shul-gallery-outline-fix"></div>
                  <ImageGallery items={images} showThumbnails={false} showPlayButton={false} autoPlay={true} showFullscreenButton={false} lazyLoad={true} />
                </div>
                <div class="shul-design-development">
                  <h2>Complete Design Development</h2>
                  <p>From defining the concept to sketch ideation to a fully functioning prototype. Our studio is equipped and skilled to take care of any aspect of the design process for your needs to provide a sexy outcome.</p>
                  <ul>
                    <li>Concept research</li>
                    <li>Market analysis and trends</li>
                    <li>Sketch phase</li>
                    <li>Concept rendering (2D)</li>
                    <li>CAD development</li>
                    <li>CAD development</li>
                    <li>Scale model fabrication</li>
                    <li>Full scale functional prototype builds</li>
                  </ul>
                </div>
              </div>

              <div class="our-team-experience">
                <h2>Our team experience:</h2>
                <div class="company-logos">
                  <div>
                    <img alt="volvo-logo" src={volvoGold} style={{ marginTop: '-2px' }}></img>
                  </div>
                  <div>
                    <img alt="yamaha-logo" src={yamahaGold}></img>
                  </div>
                  <div>
                    <img alt="rolls-royce-logo" src={rrGold} style={{ width: '130%', marginLeft: "-4px" }}></img>
                  </div>
                  <div>
                    <img alt="jaguar-logo" src={jagGold} style={{ width: '190%', marginLeft: "-15px" }}></img>
                  </div>
                  <div>
                    <img alt="land-rover-logo" src={lrGold} style={{ width: '170%', marginLeft: "-10px" }}></img>
                  </div>
                </div>
              </div>

              <div class="our-customers">
                <h2>Our customers:</h2>
                <img src={ourCustomersMap}></img>
              </div>
              <Footer></Footer>
            </Layout>

          </div>

        </div>

      </React.Fragment>
    )
  }
}

export default DesignPage
